import React from "react";
import styled from "styled-components";
import LinesEllipsis from "react-lines-ellipsis";
import { useNavigate } from "react-router-dom";
import { newsAndUpdates } from "../../../utils";
import ButtonWithBorderComponent from "../../buttons/ButtonWithBorder.component";

const NewsAndUpdateComponent = () => {
  const navigate = useNavigate();
  const newsList = newsAndUpdates;

  return (
    <Wrapper>
      <h2>News and Updates</h2>
      <BlogGallery>
        {newsList.map((news) => {
          const { date, id, description, title, image } = news;
          return (
            <BlogItem key={id}>
              <img src={image} alt="" />
              <div>
                <h4>{date} </h4>
              </div>

              <h3>
                <LinesEllipsis
                  text={title}
                  maxLine="2"
                  ellipsis="..."
                  trimRight
                  basedOn="words"
                />
              </h3>
              <h4>
                <LinesEllipsis
                  text={description}
                  maxLine="4"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </h4>

              <ButtonWrapper>
                <ButtonWithBorderComponent
                  name={"Read more.."}
                  onClick={() => navigate("/news-details", { state: { news } })}
                />
              </ButtonWrapper>
            </BlogItem>
          );
        })}
      </BlogGallery>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem;
`;

const BlogGallery = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: auto;
  grid-auto-flow: dense;
  grid-gap: 20px;

  @media (max-width: 996px) {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  @media only screen and (min-width: 1025px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    @media only screen and (min-width: 1225px) {
      /* Larger screen styles */
      padding: 50px;
      width: 70vw;
    }
  }

  @media only screen and (max-width: 350px) {
    /* Larger screen styles */
    padding: 50px 10px;
    margin: auto;
  }
`;

const BlogItem = styled.div`
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* width: 70vw; */
  width: 100%;
  height: auto;
  margin: 1rem auto;
  overflow: hidden;
  background-color: #fbfbfb;
  img {
    /* width: 70vw; */
    width: 100%;
    height: 12rem;
    border-radius: 10px;
    transition: transform 0.5s ease;
  }
  img:hover {
    transform: scale(1.04);
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    color: black;
    text-align: start;
    box-sizing: border-box;
    margin: 8px 0 1px 0;
    padding-bottom: 4px;
  }
  h4 {
    box-sizing: border-box;
    font-size: 13px;
    font-weight: lighter;
    color: black;
    margin: 4px 0;
    text-align: start;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    padding: 2rem;
  }
`;

export default NewsAndUpdateComponent;

import React, { Fragment } from "react";
import styled from "styled-components";
import { Underline } from "../../utils";
import ButtonWithBorderComponent from "../buttons/ButtonWithBorder.component";
import FooterSection from "../footer/FooterSection";
import applicationForm from "./SPINMARK MICROBUSINESS SACCO ACCOUNT OPENING FORM.pdf";
import FrequentlyAskedQuestions from "../frequently-asked-questions/FrequentlyAskedQuestionsPage";

const JoinUsComponent = () => {
  const handleDownload = () => {
    // Create a temporary link element
    const link = document.createElement("a");
    link.href = applicationForm; // Set the href attribute to the applicationForm file
    link.download = "SPINMARK_MICROBUSINESS_SACCO_ACCOUNT_OPENING_FORM.pdf"; // Specify the download filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the temporary link element
  };

  return (
    <Fragment>
      <MainWrapper>
        <Div>
          <Container>
            <Title>Join SpinMark SACCO</Title>
            <Content>
              <div>
                <TitleWrapper>
                  <H3>About Us</H3>
                  <Underline width={"75px"} />
                </TitleWrapper>

                <p>
                  <strong>SpinMark Microbusiness SACCO Society Ltd</strong> a
                  beacon since 2023, blends visionary leadership and financial
                  empowerment. We transcend finance, catalysing positive change
                  with unwavering commitment.
                </p>
              </div>

              <div>
                <TitleWrapper>
                  <H3>What We Do</H3>
                  <Underline width={"100px"} />
                </TitleWrapper>

                <p>
                  <strong>SpinMark</strong> is more than finance; it's a dynamic
                  force propelling economic growth. From Poverty Graduation
                  Initiatives to Savings Solutions and Investments, our services
                  redefine financial empowerment.
                </p>
              </div>

              <div>
                <TitleWrapper>
                  <H3>Why Us</H3>
                  <Underline width={"60px"} />
                </TitleWrapper>

                <p>
                  Its visionary leadership, unwavering commitment to Islamic
                  finance, and a transformative suite of services. Pioneering
                  financial inclusion with integrity is our reality.
                </p>
              </div>
              <TitleWrapper>
                <H3>Eligibility Requirements</H3>
                <Underline width={"200px"} />
              </TitleWrapper>
              <Paragraph>
                While membership is open, SpinMark SACCO admits only eligible
                individuals, groups, or corporates upon fulfillment of the base
                requirements below: –
              </Paragraph>
              <ul>
                <li>A copy of the KRA PIN Certificate</li>
                <li>
                  A copy of a recent payslip, letter of appointment, or bank
                  statement
                </li>
                <li>
                  A copy of National/Alien ID/Passport or Birth Certificate for
                  a minor account
                </li>
                <li>Registration Fee of Kes. 500 only</li>
                <li>Attached copy of the membership application form</li>
                <li>Colored passport-sized photographs</li>
              </ul>
              <SectionTitle>Registration Steps</SectionTitle>
              <Paragraph>
                Download the application form below, fill in all the required
                fields, scan it, and send it to{" "}
                <strong>info@spinmarksacco.com</strong> together with the
                required documents. You can also visit our offices at Kimathi
                Street, Nairobi.
              </Paragraph>

              <DownloadWrapper>
                <ButtonWithBorderComponent
                  name={"Download application form"}
                  onClick={handleDownload}
                />
              </DownloadWrapper>

              <br />
              <br />
            </Content>
          </Container>
          <FrequentlyAskedQuestions />
        </Div>
      </MainWrapper>

      <FooterSection />
    </Fragment>
  );
};
const DownloadWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  margin: 0 2rem 1rem 2rem;
`;

const H3 = styled.h3`
  margin: 0;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 32px;
  /* max-width: 70vw; */
`;

const Title = styled.h1`
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
`;

const Content = styled.div``;

const SectionTitle = styled.h2`
  font-size: 20px;
  margin-top: 30px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.6;
`;

const Div = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 2fr); /* 2 columns */
  grid-gap: 10px; /* Gap between grid items */
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr; /* 1 column */
  }
`;
const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px;
`;
export default JoinUsComponent;

import styled from "styled-components";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { landingPageSlidersData } from "../../utils";

function SliderComponent() {
  return (
    <div>
      <Splide
        options={{
          perPage: 1,
          arrows: true,
          pagination: false,
          drag: "free",
          gap: "2rem",
        }}
      >
        {landingPageSlidersData.map((slider, index) => {
          return (
            <SplideSlide key={slider.id}>
              <Card>
                <Gradient />
                <div>
                  <h3>{slider.header}</h3>
                  <p>{slider.description}</p>
                </div>

                <img src={slider.image} alt={slider.header} />
              </Card>
            </SplideSlide>
          );
        })}
      </Splide>
    </div>
  );
}

const Wrapper = styled.div`
  margin: 4rem 0rem;
`;

const Card = styled.div`
  min-height: 25rem;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  div {
    position: absolute;
    z-index: 10;
    left: 50%;
    width: 100%;
    bottom: 0%;
    transform: translate(-50%, 0%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
  }

  h3 {
    color: white;
    text-align: center;
    font-size: 1.3rem;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  p {
    color: white;
    text-align: center;
    font-size: 0.9rem;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: lighter;
  }
`;
const Gradient = styled.div`
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
`;

export default SliderComponent;

import React, { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const NewsDetailsPage = () => {
  const location = useLocation();
  const [newsDetails, setNewsDetails] = useState(location.state.news);

  const { date, description, title, image } = newsDetails;
  return (
    <DetailesWrapper>
      <img src={image} alt={title} />
      <h3>{date} </h3>
      <h2>{title}</h2>
      <h3>{description}</h3>
    </DetailesWrapper>
  );
};

const DetailesWrapper = styled.div`
  border-radius: 0.5rem 0%.5 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: flex;
  width: 70%;
  margin: 3rem auto;
  /* [1] The container */
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */

  img {
    width: 100%;
    border-radius: 0.5rem;
    /* [2] Transition property for smooth transformation of images */
    transition: transform 0.5s ease;
    max-height: 25rem;
  }
  img:hover {
    /* [3] Finally, transforming the image when container gets hovered */
    transform: scale(1.04);
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h3 {
    box-sizing: border-box;
    font-size: 16px;
    font-weight: lighter;
    color: black;
    margin: 11px 0;
    text-align: start;
    line-height: 1.5;
  }
  h2 {
    font-size: 20px;
    font-weight: bold;
    color: black;
    text-align: start;
    box-sizing: border-box;
    margin: 0 0 10px 0;
  }

  @media only screen and (max-width: 600px) {
    /* Mobile styles */
  }

  @media only screen and (min-width: 601px) and (max-width: 768px) {
    /* Tablet styles */
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    /* Laptop styles */
  }

  @media only screen and (min-width: 1225px) {
    /* Larger screen styles */
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    width: 60vw;
    img {
      height: 35rem;
    }
  }
`;

export default NewsDetailsPage;

import React, { Fragment } from "react";
import styled from "styled-components";
import { FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const FooterSocialLinks = () => {
  const year = new Date().getFullYear();
  const socialLinks = [
    {
      id: 1,
      icon: <FaFacebook />,
      url: "https://www.facebook.com/",
    },
    {
      id: 2,
      icon: <FaInstagram />,
      url: "https://www.instagram.com/",
    },
    {
      id: 3,
      icon: <FaXTwitter />,
      url: "https://twitter.com/",
    },
    {
      id: 4,
      icon: <FaLinkedin />,
      url: "https://www.Linkedin.com/",
    },
  ];
  return (
    <Div>
      <FooterContainer>
        <div>
          {socialLinks.map((link) => (
            <a href={link.url} target="__blank" key={link.id} className="icons">
              <Fragment>{link.icon}</Fragment>
            </a>
          ))}
        </div>
        <span>
          <span style={{ color: "var(--bg-color)" }}>©</span> Copyright {year}{" "}
          Spinmark SACCO
        </span>
      </FooterContainer>
    </Div>
  );
};

const Div = styled.div`
  height: 4rem; 
`;
const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center; 
  margin: 2rem 16px 1.5rem 16px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    .icons {
      color: var(--bg-color);
    }
  }

  
  @media only screen and (max-width: 600px) {
    /* Mobile styles */ 
      display: flex;
      flex-direction: column; 
      align-items: center;
      gap: 1rem; 
      margin:0 16px;
  }

  @media only screen and (min-width: 601px) and (max-width: 768px) {
    /* Tablet styles */ /
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    /* Laptop styles */ 
  }

  @media only screen and (min-width: 1225px) {
    /* Larger screen styles */ 
    width: 70vw;
    margin: 2rem auto;
    padding: 0;
  }
`;

export default FooterSocialLinks;

import React, { Fragment } from "react";
import styled from "styled-components";
import LinesEllipsis from "react-lines-ellipsis";
import { useNavigate } from "react-router-dom";
import { Underline, ourServices } from "../../utils";
import ButtonWithBorderComponent from "../buttons/ButtonWithBorder.component";

const OurServiceComponent = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <TitleWrapper>
        <H3>Our Services</H3>
        <Underline width={"100px"} />
      </TitleWrapper>

      <BlogGallery>
        {ourServices.map((news, index) => {
          const { id, description, title, image } = news;
          return (
            <BlogItem key={id}>
              <img src={image} alt="" />

              <h3>
                <LinesEllipsis
                  text={title}
                  maxLine="1"
                  ellipsis=""
                  trimRight
                  basedOn="words"
                />
              </h3>
              <h4>
                <LinesEllipsis
                  text={description}
                  maxLine="4"
                  ellipsis=""
                  trimRight
                  basedOn="letters"
                />
              </h4>

              <ButtonWrapper>
                <ButtonWithBorderComponent
                  name={"Read more.."}
                  width={"150px"}
                  onClick={() =>
                    navigate("/quick-links-details", { state: { index } })
                  }
                />
              </ButtonWrapper>
            </BlogItem>
          );
        })}
      </BlogGallery>
    </Fragment>
  );
};

export default OurServiceComponent;
const H3 = styled.h3`
  margin: 2rem 0 4px 0;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem;
`;
const BlogGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default: 4 columns */
  margin: 0 16px;

  @media (max-width: 480px) {
    /* Mobile styles small */
    grid-template-columns: 1fr; /* 1 column */
    margin: 0 16px;
  }

  @media only screen and (min-width: 481px) and (max-width: 600px) {
    /* Mobile styles */
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    margin: 4px 16px;
  }

  @media only screen and (min-width: 601px) and (max-width: 768px) {
    /* Tablet styles */
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    margin: 4px 16px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1224px) {
    /* Laptop styles */
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    margin: 4px 40px;
  }

  @media only screen and (min-width: 1225px) {
    /* Larger screen styles */
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    margin: 0 auto;
    width: 70vw;
    padding: 0;
  }
`;

const BlogItem = styled.div`
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 14rem;
  height: auto;
  margin: 1rem auto;
  overflow: hidden;
  background-color: #f2f7f2;
  @media (max-width: 480px) {
    /* Mobile styles small*/
    max-width: 100%;
  }
  @media only screen and (min-width: 481px) and (max-width: 600px) {
    /* Mobile styles */
    max-width: 100%;
    margin: 4px;
  }

  @media only screen and (min-width: 601px) and (max-width: 768px) {
    /* Tablet styles */
    max-width: 100%;
    margin: 4px;
  }

  @media only screen and (min-width: 1225px) {
    /* Larger screen styles */
    max-width: 100%;
    margin: 8px;
  }
  img {
    /* width: 70vw; */
    width: 100%;
    height: 10rem;
    border-radius: 10px;
    transition: transform 0.5s ease;
  }
  img:hover {
    transform: scale(1.04);
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    color: black;
    text-align: start;
    box-sizing: border-box;
    margin: 8px 0 1px 0;
    padding-bottom: 4px;
  }
  h4 {
    box-sizing: border-box;
    font-size: 13px;
    font-weight: lighter;
    color: black;
    margin: 4px 0;
    text-align: start;
  }
`;

import React, { useState } from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import styled from "styled-components";
import { generalFaqs } from "../../utils";

const GeneralFAQs = () => {
  const [selectedUnitIndex, setSelectedUnitIndex] = useState(null);

  const toggleCard = (index) => {
    if (selectedUnitIndex === index) {
      setSelectedUnitIndex(null);
    } else {
      setSelectedUnitIndex(index);
    }
  };

  return (
    <div> 
      {generalFaqs.map((faq, index) => (
        <Card key={index}>
          <CardHeader onClick={() => toggleCard(index)}>
            <ArrowIcon isOpen={selectedUnitIndex === index} />
            <h4>{faq.question}</h4>
          </CardHeader>
          {selectedUnitIndex === index && (
            <CardBody>
              <p>{faq.answer}</p>
            </CardBody>
          )}
        </Card>
      ))}
    </div>
  );
};

const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 10px 16px;

  p {
    margin: 10px 0;
  }
  h4 {
    margin: 10px;
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 16px;
`;

const CardBody = styled.div`
  padding: 0 16px;
`;

const ArrowIcon = styled(({ isOpen, ...props }) =>
  isOpen ? <FaAngleDown {...props} /> : <FaAngleRight {...props} />
)`
  font-size: 20px;
  color: var(--primary-color);
  margin-right: 8px;
`;

export default GeneralFAQs;

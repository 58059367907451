import React, { useState } from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import styled from "styled-components";
import { ourServices } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import ButtonWithBorderComponent from "../buttons/ButtonWithBorder.component";

const QuickLinksDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedUnitIndex, setSelectedUnitIndex] = useState(
    location.state.index
  );

  const toggleCard = (index) => {
    if (selectedUnitIndex === index) {
      setSelectedUnitIndex(null);
    } else {
      setSelectedUnitIndex(index);
    }
  };

  return (
    <div>
      <H3>Quick link to our services</H3>
      {ourServices.map((services, index) => (
        <Card key={index}>
          <CardHeader onClick={() => toggleCard(index)}>
            <ArrowIcon isOpen={selectedUnitIndex === index} />
            <h4>{services.title}</h4>
          </CardHeader>
          {selectedUnitIndex === index && (
            <CardBody>
              <p>{services.description}</p>

              <JoinUsButtonWrapper>
                <ButtonWithBorderComponent
                  name={"JOIN US TO GET IT"}
                  onClick={() => navigate("/join-us", { replace: true })}
                />
              </JoinUsButtonWrapper>
            </CardBody>
          )}
        </Card>
      ))}
    </div>
  );
};

const JoinUsButtonWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  margin: 1rem 2rem;
`;

const H3 = styled.h3`
  margin: 24px 16px;
`;
const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 10px 16px;

  p {
    margin: 10px 0;
  }
  h4 {
    margin: 10px;
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 16px;
`;

const CardBody = styled.div`
  padding: 0 16px;
`;

const ArrowIcon = styled(({ isOpen, ...props }) =>
  isOpen ? <FaAngleDown {...props} /> : <FaAngleRight {...props} />
)`
  font-size: 20px;
  color: var(--primary-color);
  margin-right: 8px;
`;

export default QuickLinksDetails;

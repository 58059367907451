import FooterSection from "../../components/footer/FooterSection";
import AboutSection from "../../components/about/AboutSection";
import LandingPage from "../../components/landing-page/LandingPage";
import OurServiceComponent from "../../components/our-services/OurServices.component";

const Home = () => {
  return (
    <div>
      <LandingPage />
      <OurServiceComponent />
      <AboutSection />
      <FooterSection />
    </div>
  );
};

export default Home;

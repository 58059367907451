import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./routes/home/Home";
import Navigation from "./routes/navigation/Navigation";
import NewsAndUpdatesPage from "./routes/news-and-update/NewsAndUpdatesPage";
import OurServiceComponent from "./components/our-services/OurServices.component";
import JoinUsComponent from "./components/join-us/JoinUs.component";
import FrequentlyAskedQuestionsPage from "./components/frequently-asked-questions/FrequentlyAskedQuestionsPage";
import NewsDetailsPage from "./components/news/new-details/NewsDetailsPage";
import QuickLinksDetails from "./components/quick-links/QuickLinksDetails";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/*  // home path(all rotes are within the home path)*/}

        <Route path="/" element={<Navigation />}>
          <Route index element={<Home />} />
          <Route path="/news" element={<NewsAndUpdatesPage />} />
          <Route path="/our-services" element={<OurServiceComponent />} />
          <Route path="/FAQs" element={<FrequentlyAskedQuestionsPage />} />
          <Route path="/join-us" element={<JoinUsComponent />} />
          <Route path="/news-details" element={<NewsDetailsPage />} />
          <Route path="/quick-links-details" element={<QuickLinksDetails />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default App;

import React from "react"; 
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components"; 
import "swiper/css";
import "swiper/css/effect-cards"; 
import "../../App.css"; 
import { EffectCards } from "swiper/modules"; 
import ButtonWithBorderComponent from "../buttons/ButtonWithBorder.component";
import { Underline, ourServices } from "../../utils";
import { useNavigate } from "react-router";


const OurServicesCards = () => {
  const navigate = useNavigate()
  return (
    <Div>
      <TitleWrapper>
        <H3>Our Services cards</H3>
        <Underline width={"150px"} />
      </TitleWrapper>
      <Swiper
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
      >
        {ourServices.map((service, index) => {
          return (
            <SwiperSlide>
              <Container>
                <img src={service.image} alt={service.title} />
                <CardWrapper>
                  <h3>{service.title}</h3>
                  <p>{service.desc}</p>
                  <ButtonWithBorderComponent
                    name={"Learn More..."}
                    width={"130px"}
                    onClick={() =>
                      navigate("/quick-links-details", { state: { index } })
                    }
                  />
                </CardWrapper>
              </Container>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Div>
  );
};

export default OurServicesCards;
const Div = styled.div` 
`;
const H3 = styled.h3`
  margin: 0 0 5px 0;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;
`;
const Container = styled.div`
  width: 240px;
  height: 320px;
  background-color: rgba(33, 54, 67, 0.3);
  img {
    width: 240px;
    height: 320px;
    position: absolute;
    z-index: -1;
  }

  h3 {
    font-size: 17px;
    font-weight: bold;
    margin: 0;
    color: white;
  }
  p {
    font-size: 16px;
    font-weight: lighter;
    line-height: 1.5;
  }
`;
const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* position: relative; */
  p {
    text-align: center;
  }
`;

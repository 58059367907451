import React from "react";
import styled from "styled-components";
import { Underline, newsAndUpdates } from "../../../utils";
import { useNavigate } from "react-router";
import LinesEllipsis from "react-lines-ellipsis";

const LatestNews = () => {
  const navigate = useNavigate();
  const firstThreeNews = newsAndUpdates.slice(0, 3); // Slice the first 3 items

  return (
    <Div>
      <TitleWrapper>
        <H3>Latest news</H3>
        <Underline width={"100px"} />
      </TitleWrapper>

      {firstThreeNews.map((news) => {
        return (
          <Wrapper
            key={news.id}
            onClick={() => navigate("/news-details", { state: { news } })}
          >
            <img src={news.image} alt="" />
            <div>
              <p>
                <LinesEllipsis
                  text={news.title}
                  maxLine="1"
                  ellipsis=""
                  trimRight
                  basedOn="words"
                />
              </p>
              <p>{news.date}</p>
            </div>
          </Wrapper>
        );
      })}

      {newsAndUpdates.length > 4 && (
        <TitleWrapper onClick={() => navigate("/news")}>
          <p> Read more... </p>
        </TitleWrapper>
      )}
    </Div>
  );
};
const Div = styled.div``;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;
const H3 = styled.h3`
  margin: 0;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;

  p {
    margin: 2px 0;
    font-size: 16px;
    cursor: pointer;
    background-color: rgba(64, 146, 93, 0.2);
    padding: 3px;
    border-radius: 3px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  padding: 4px;

  img {
    height: 60px;
    width: 70px;
    border-radius: 8px;
  }
  gap: 4px;
  div {
    display: flex;
    justify-content: space-between;
    /* align-items: flex-start; */
    flex-direction: column;

    p {
      margin: 3px;
    }
  }
`;

export default LatestNews;

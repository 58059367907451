import { Fragment, useState } from "react";
import { Link } from "react-scroll";
import { Outlet, Link as RouteLink } from "react-router-dom";
import styled from "styled-components";
import { FaBars, FaTimes } from "react-icons/fa"; // Importing icons for the menu and close buttons
import { useLocation } from "react-router-dom";
import logo from "../../assets/logo/spinmark_logo.jpg";

const Navigation = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <Fragment>
      <NavContainer>
        <RouteLink to="/">
          <img className="logo" src={logo} alt="logo" />
        </RouteLink>
        <MobileMenuIcon onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </MobileMenuIcon>
        <div
          className={`nav-links ${isMobileMenuOpen ? "mobile-menu-open" : ""}`}
        >
          {/* if you're in home page scroll smothly to About section otherwise navigate to home page */}
          {currentPath === "/" ? (
            <Link
              offset={-100}
              to="about"
              spy={true}
              smooth={true}
              onClick={toggleMobileMenu}
              style={{ cursor: "pointer", color: " #40925d" }}
            >
              About Us
            </Link>
          ) : (
            <RouteLink
              to="/"
              onClick={toggleMobileMenu}
              style={{ cursor: "pointer", color: " #40925d" }}
            >
              About Us
            </RouteLink>
          )}

          <RouteLink
            to="our-services"
            onClick={toggleMobileMenu}
            style={{ color: " #40925d" }}
          >
            Our Services
          </RouteLink>

          <RouteLink
            to="/news"
            onClick={toggleMobileMenu}
            style={{ color: " #40925d" }}
          >
            News & Updates
          </RouteLink>

          {/* if you're in home page scroll smothly to Contact section otherwise navigate to home page */}
          {currentPath === "/" ? (
            <Link
              offset={-100}
              to="contact"
              spy={true}
              smooth={true}
              onClick={toggleMobileMenu}
              style={{ cursor: "pointer", color: " #40925d" }}
            >
              Contact Us
            </Link>
          ) : (
            <RouteLink
              to="/"
              onClick={toggleMobileMenu}
              style={{ color: " #40925d" }}
            >
              Contact Us
            </RouteLink>
          )}

          <RouteLink
            to="/FAQs"
            onClick={toggleMobileMenu}
            style={{ color: " #40925d" }}
          >
            FAQs
          </RouteLink>
          <RouteLink
            to="/join-us"
            onClick={toggleMobileMenu}
            style={{ color: " #40925d" }}
          >
            Join Us
          </RouteLink>
        </div>
      </NavContainer>

      <Outlet />
    </Fragment>
  );
};

const NavContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #F2F7F2; */
  padding: 0.4rem 16px;

  .logo {
    height: 60px;
    width: 80px;
    border-radius: 50%;
  }

  .nav-links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
  }

  @media (max-width: 768px) {
    .nav-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 65px;
      left: 0;
      width: 100%;
      background-color: #fff;
      z-index: 2000;
      text-align: center;
      padding: 1rem;
    }

    .nav-links.mobile-menu-open {
      display: flex;
    }
  }
`;

const MobileMenuIcon = styled.div`
  display: none;
  cursor: pointer;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Gradient = styled.div`
  z-index: -3;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(22, 84, 54, 1),
    rgba(22, 84, 54, 0.8),
    rgba(22, 84, 54, 0.5)
  );
`;

export default Navigation;

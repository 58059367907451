import React from "react";
import styled from "styled-components";

const ButtonComponent = ({
  disabled,
  name,
  type,
  className,
  onClick,
  width,
}) => {
  return (
    <div>
      <Button
        disabled={disabled}
        width={width}
        type={type}
        className={className}
        onClick={onClick}
      >
        {name}
      </Button>
    </div>
  );
};

const Button = styled.button`
  cursor: pointer;
  /* background-color: ${(props) => props.bgColor || "var(--bg-color)"}; */
  background-color: var(--bg-color);
  padding: 10px 0;
  border-radius: 5px;
  border: none;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: inherit;
  color: var(--font-color);
  transition: all 0.3s ease;
  width: ${(props) => props.width || "15rem"};
  min-width: 4rem;

  &:hover {
    background-color: rgbA(64, 146, 93, 0.8);
    color: rgba(0, 0, 0, 0.7);
  }
`;
export default ButtonComponent;

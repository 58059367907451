import React from "react";
import AboutSpinmarkFooterComponent from "../about/AboutSpinmark.footer.component";
import LatestNews from "../news/latest-news/LatestNews.component";
import QuickLinks from "../quick-links/QuickLinks.component";
import ContactInfo from "../contact-us/ContactInfo.component";
import styled from "styled-components";
import FooterSocialLinks from "./FooterSocialLinks.component";

const FooterSection = () => {
  return (
    <Container>
      <Wrapper>
        <Div>
          <AboutSpinmarkFooterComponent />
          <LatestNews />
          <QuickLinks />
          <ContactInfo />
        </Div>
      </Wrapper>

      <FooterSocialLinks />
      <Gradient />
    </Container>
  );
};

export default FooterSection;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
const Div = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-gap: 30px 20px; /* Gap between grid items */
  justify-content: center;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr; /* 1 column */
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center; /* horizontally center */
  align-items: center; /* vertically center */
  margin: 16px;
`;

const Gradient = styled.div`
  z-index: -3;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(22, 84, 54, 0),
    rgba(22, 84, 54, 0.3),
    rgba(22, 84, 54, 0.5)
  );
`;

import React from "react";
import styled from "styled-components";

const AboutSpinmarkFooterComponent = () => {
  return (
    <Div>
      <h3>Spinmark SACCO</h3>
      <p>
        SpinMark Microbusiness SACCO Society Ltd, established in 2023, stands as
        a beacon of visionary leadership and financial empowerment. We transcend
        finance, catalyzing positive change with unwavering commitment to
        Islamic finance principles. Our comprehensive suite of member-driven
        services, including Poverty Graduation Initiatives and Investment
        Diversification, redefines financial empowerment. Join us at SpinMark,
        where pioneering financial inclusion with integrity is not just a
        mission, but a reality.
      </p>
    </Div>
  );
};

const Div = styled.div` 
`;
export default AboutSpinmarkFooterComponent;

import { CircularProgress } from "@mui/material";

//services loan images
import styled from "styled-components";
import bussines_loans from "../src/assets/services-images/bussiness-loan.jpg";
import personal_loan from "../src/assets/services-images/personal-loans.jpeg";
import students_loans from "../src/assets/services-images/student-loans.avif";
import real_state from "../src/assets/services-images/real-estate-assets.jpg";
import emergency from "../src/assets/services-images/emergency.jpg";
import sharia_compliance from "../src/assets/services-images/SHARIA-COMPLIANT-FINANCING-AND-MORTGAGES.jpg";
import group_loans from "../src/assets/services-images/group-loans.jpeg";
import salaried_loans from "../src/assets/services-images/salaried-loans.jpg";

//news and update images
import sharia_compliance_loan_news from "../src/assets/news-images/sharia-complaince-loans.jpg";
import loan_mng_news from "../src/assets/news-images/loan-mng.jpg";
import community_news from "../src/assets/news-images/community.jpg";
import salary_deposit_news from "../src/assets/news-images/salary-deposit.jpg";
import student_news from "../src/assets/news-images/students.jpg";
import sharia_low_news from "../src/assets/news-images/sharia-low.jpg";
import real_state_man_news from "../src/assets/news-images/real-state-man.jpeg";
import emergency_loans_news from "../src/assets/news-images/emergency-loans.jpg";
import personal_finances_news from "../src/assets/news-images/personal-finances.jpg";
import bussines_growth_news from "../src/assets/news-images/bussiness-growth.jpg";

//sliders images
import islamic_banking from "../src/assets/sliders-images/islamic-banking.jpg";
import financial_inclusion from "../src/assets/sliders-images/financial-inclusion.jpg";
import economic_stability from "../src/assets/sliders-images/econommic-stability.jpg";
import trust from "../src/assets/sliders-images/trust.jpg";

export const landingPageSlidersData = [
  {
    image: islamic_banking,
    header: "Providing Sharia-Compliant Financial Solutions",
    description:
      "At Spinmark SACCO, we are dedicated to offering Sharia-compliant financial solutions that align with Islamic principles. Our commitment to ethical finance ensures that our customers can access the funding they need while adhering to their religious teachings. Explore our range of services to discover how we can support your financial journey.",
  },
  {
    image: community_news,
    header: "Empowering Communities Through Ethical Financing",
    description:
      "We believe in the power of ethical finance to uplift communities and promote economic prosperity. With our Sharia-compliant loan products, we empower individuals and businesses to achieve their goals while contributing to the welfare of society. Join us in building a better future through responsible financial practices.",
  },
  {
    image: financial_inclusion,
    header: "Fostering Financial Inclusion Through Islamic Finance",
    description:
      "Our mission is to foster financial inclusion through Islamic finance principles. By providing access to Sharia-compliant loans and financial services, we aim to empower individuals from all walks of life to participate in the economy and achieve their financial aspirations.",
  },
  {
    image: economic_stability,
    header: "Promoting Economic Stability With Ethical Banking",
    description:
      "We are committed to promoting economic stability through ethical banking practices. Our Sharia-compliant financial solutions not only support individual financial goals but also contribute to the overall stability and resilience of the economy.",
  },
  {
    image: trust,
    header: "Building Trust Through Transparency and Integrity",
    description:
      "Transparency and integrity are at the core of our operations. We believe in building trust with our customers by providing clear and transparent Sharia-compliant financial products and services. With us, you can be confident in your financial decisions.",
  },
  // Add more slides as needed
];

export const Underline = styled.div`
  height: 3px;
  width: ${(props) => props.width || "200px"};
  background-color: var(--bg-color);
  border-radius: 10px;
  margin: 0 0 4px 0;
`;

export const islamicComplianceFaqs = [
  {
    question: "What does it mean for your loans to be Sharia-compliant?",
    answer:
      "Sharia-compliant loans adhere to Islamic finance principles, avoiding interest (riba) and complying with Sharia law in all aspects of the loan structure and transactions.",
  },
  {
    question: "How do you ensure that your loans avoid riba (interest)?",
    answer:
      "We structure our loans based on Islamic financial contracts that do not involve interest, such as Murabaha, Ijarah, or Musharakah, ensuring that all transactions are interest-free.",
  },
  {
    question:
      "Do you have a Sharia supervisory board overseeing your loan products?",
    answer:
      "Yes, we have a Sharia supervisory board consisting of Islamic scholars who ensure that our loan products and operations comply with Islamic principles and ethical guidelines.",
  },
  {
    question:
      "What types of Islamic financial contracts do you use for your loans?",
    answer:
      "We primarily use contracts such as Murabaha (cost-plus-profit sale), Ijarah (leasing), or Musharakah (partnership) to structure our loans in accordance with Sharia principles.",
  },
  {
    question: "Are your loans transparent in terms of profit margins and fees?",
    answer:
      "Yes, we maintain transparency in all our transactions, clearly disclosing profit margins, fees, and other charges associated with our loan products to ensure mutual understanding and compliance with Sharia principles.",
  },
  {
    question:
      "Do you offer any loan forgiveness or deferment options in accordance with Islamic finance principles?",
    answer:
      "Yes, we may offer loan forgiveness programs for individuals working in certain professions or deferment options in case of economic hardship, aligning with the principles of benevolence and social responsibility in Islamic finance.",
  },
  {
    question:
      "How do you handle collateral and risk management in your Sharia-compliant loans?",
    answer:
      "We utilize asset-based financing and risk-sharing mechanisms to ensure that loans are secured by tangible assets and that risks are mitigated in accordance with Islamic finance principles.",
  },
  {
    question:
      "Are your loans structured to promote social welfare and economic development in the community?",
    answer:
      "Yes, we design our loan products to support community empowerment, economic development, and financial inclusion, aligning with the principles of Islamic finance that emphasize social responsibility and equitable distribution of wealth.",
  },
  {
    question:
      "What measures do you take to ensure compliance with Islamic ethical guidelines in your loan operations?",
    answer:
      "We adhere to strict ethical guidelines, ensuring that all our loan operations are conducted in a manner consistent with Islamic values, fairness, and justice.",
  },
  {
    question:
      "How do you handle situations where borrowers face financial difficulties or unforeseen circumstances?",
    answer:
      "We offer flexibility and support to borrowers facing financial hardships, considering temporary relief measures or loan restructuring options while ensuring the sustainability of our operations and adherence to Islamic finance principles.",
  },
];

export const generalFaqs = [
  {
    question:
      "What is a personal loan, and how does it differ from other types of loans?",
    answer:
      "A personal loan is an unsecured loan offered by financial institutions for various personal purposes, such as debt consolidation, home improvements, or emergencies. Unlike other loans, personal loans do not require collateral.",
  },
  {
    question:
      "What factors determine the loan amount and interest rate for a personal loan?",
    answer:
      "The loan amount is determined based on the borrower's creditworthiness, income, and financial factors. Interest rates may be fixed or variable, depending on market conditions and the lender's terms.",
  },
  {
    question: "What documents are required to apply for a personal loan?",
    answer:
      "Borrowers typically need to provide personal and financial information, including income verification, employment details, credit history, and other relevant documents.",
  },
  {
    question: "What are the repayment terms for personal loans?",
    answer:
      "Repayment terms vary, ranging from a few months to several years. Borrowers select a repayment period based on their ability to manage monthly payments and desired pay-off timeline.",
  },
  {
    question: "Can personal loans be used for any purpose?",
    answer:
      "Yes, personal loans can be used for various purposes, including debt consolidation, home improvements, medical expenses, weddings, vacations, or other personal financial needs.",
  },
  {
    question: "What are the fees associated with personal loans?",
    answer:
      "Personal loans may come with fees such as origination fees, prepayment penalties, late payment fees, or annual fees. Understanding these fees is important to calculate the total cost of the loan.",
  },
  {
    question:
      "How quickly can I access funds once my personal loan is approved?",
    answer:
      "Once approved, borrowers typically receive the loan amount in a lump sum, providing quick access to funds to address their financial needs.",
  },
  {
    question:
      "Are there any credit requirements for obtaining a personal loan?",
    answer:
      "We assess the borrower's creditworthiness, income stability, debt-to-income ratio, and credit history to determine eligibility and interest rates offered.",
  },
  {
    question: "Can I make extra payments or pay off my personal loan early?",
    answer:
      "Yes, we allow borrowers to make extra payments or pay off the loan early without penalties, helping save on interest costs.",
  },
  {
    question:
      "What is the difference between fixed and variable interest rates for personal loans?",
    answer:
      "Fixed interest rates remain constant throughout the loan term, providing predictable monthly payments. Variable rates can fluctuate based on market conditions, potentially affecting monthly payments.",
  },
  {
    question: "Is collateral required for personal loans?",
    answer:
      "Personal loans are typically unsecured, meaning they do not require collateral. Borrowers are approved based on creditworthiness rather than pledged assets.",
  },
  {
    question: "Are there any penalties for late payments on personal loans?",
    answer:
      "Personal loans may incur late payment fees for missed or late payments. Borrowers should be aware of these fees and adhere to the repayment schedule to avoid penalties.",
  },
  {
    question:
      "Can I apply for a personal loan if I have less-than-perfect credit?",
    answer:
      "We offer personal loans to individuals with less-than-perfect credit, but interest rates and terms may vary. It's essential to review eligibility criteria and explore options that fit your financial situation.",
  },
  {
    question: "What happens if I default on a personal loan?",
    answer:
      "Defaulting on a personal loan can have serious consequences, including damage to credit scores, collection efforts by the lender, and potential legal action to recover the outstanding debt.",
  },
  {
    question:
      "How do I know if a personal loan is the right option for my financial needs?",
    answer:
      "Consider factors such as the purpose of the loan, interest rates, fees, repayment terms, and your ability to repay before deciding if a personal loan aligns with your financial goals.",
  },
];

export const newsAndUpdates = [
  {
    id: 1,
    title: "New Sharia-Compliant Seasonal Loans Launched",
    date: "February 21, 2024",
    description:
      "We are excited to announce the launch of our new Sharia-compliant seasonal loans, designed to assist individuals and businesses experiencing seasonal fluctuations in income or expenses. These loans are structured in compliance with Islamic finance principles, avoiding riba (interest) and adhering to Sharia law.",
    image: sharia_compliance_loan_news,
  },
  {
    id: 2,
    title: "Enhanced Loan Management Services Now Available",
    date: "February 21, 2024",
    description:
      "We are pleased to introduce our enhanced loan management services tailored for organizations supporting youth and women through loans and financial assistance. Our services ensure that the right beneficiaries benefit from these programs while facilitating follow-ups on implementation and timely repayments.",
    image: loan_mng_news,
  },
  {
    id: 3,
    title: "Expanding Our Portfolio: Group Loans for Community Empowerment",
    date: "February 21, 2024",
    description:
      "We are expanding our portfolio with Sharia-compliant group loans, also known as Qard Hasan or Islamic microfinance group lending. These loans are designed to support group-based economic activities while complying with Sharia law, emphasizing cooperation, social responsibility, and ethical finance.",
    image: community_news,
  },
  {
    id: 4,
    title: "New Service Announcement: Sharia-Compliant Salary-Based Loans",
    date: "February 21, 2024",
    description:
      "Introducing our latest service offering: Shariah-compliant salary-based loans. These loans are structured in compliance with Islamic finance principles, avoiding riba (interest), and are designed to assist individuals with immediate financial needs while adhering to Sharia law.",
    image: salary_deposit_news,
  },
  {
    id: 5,
    title: "Educational Loans: Empowering Minds, Enriching Lives",
    date: "February 21, 2024",
    description:
      "We are committed to empowering individuals through education with our Sharia-compliant education loans. These loans cover a wide range of expenses associated with education, providing flexible repayment terms and options for loan forgiveness or deferment.",
    image: student_news,
  },
  {
    id: 6,
    title: "Asset-Backed Financing: Securing Your Future",
    date: "February 21, 2024",
    description:
      "Discover the benefits of our asset-backed financing options, where loans are secured by collateral, reducing risk for both borrowers and lenders. Our Sharia-compliant asset-backed loans offer no interest rates and flexible repayment terms.",
    image: sharia_low_news,
  },
  {
    id: 7,
    title: "Understanding Sharia-Compliant Housing Loans",
    date: "February 21, 2024",
    description:
      "Learn more about our Sharia-compliant housing loans, also known as Murabaha-based home financing. These loans follow alternative structures to avoid interest (riba) while providing transparent financing terms and fixed repayment schedules.",
    image: real_state_man_news,
  },
  {
    id: 8,
    title: "Emergency Loans: Providing Relief When You Need It Most",
    date: "February 21, 2024",
    description:
      "Explore our emergency loan services designed to provide quick access to funds during unforeseen financial crises or urgent situations. Our Sharia-compliant emergency loans offer fast approval and disbursement with no need for collateral.",
    image: emergency_loans_news,
  },
  {
    id: 9,
    title: "Personal Loans: Tailored Solutions for Your Financial Needs",
    date: "February 21, 2024",
    description:
      "Discover our range of Sharia-compliant personal loans, offering fixed repayment terms and competitive profit rates. Whether you need funds for debt consolidation, home improvements, or personal expenses, we have a solution for you.",
    image: personal_finances_news,
  },
  {
    id: 10,
    title: "Business Loans: Fueling Growth, Supporting Ventures",
    date: "February 21, 2024",
    description:
      "Unlock the potential of your business with our Sharia-compliant business loans tailored to meet your specific needs. From working capital to equipment financing, we provide flexible financing solutions to fuel your growth.",
    image: bussines_growth_news,
  },
];

export const ourServices = [
  {
    id: 1,
    title: "Business Loans",
    description:
      "Unlock the potential of your business with our Sharia-compliant business loans tailored to meet your specific needs. From working capital to equipment financing, we provide flexible financing solutions to fuel your growth.",
    image: bussines_loans,
    buttonName: "Explore Business Loans",
  },
  {
    id: 2,
    title: "Personal Loans",
    description:
      "Discover our range of Sharia-compliant personal loans, offering fixed repayment terms and competitive profit rates. Whether you need funds for debt consolidation, home improvements, or personal expenses, we have a solution for you.",
    image: personal_loan,
    buttonName: "Explore Personal Loans",
  },
  {
    id: 3,
    title: "Education Loans",
    description:
      "Empower yourself with our Sharia-compliant education loans covering a wide range of educational expenses. Flexible repayment terms, loan forgiveness options, and easy application process available.",
    image: students_loans,
    buttonName: "Explore Education Loans",
  },
  {
    id: 4,
    title: "Asset-Backed Financing",
    description:
      "Secure your future with our Sharia-compliant asset-backed financing options. Lower interest rates, flexible repayment terms, and reduced risk for both borrowers and lenders.",
    image: real_state,
    buttonName: "Explore Asset Financing",
  },
  {
    id: 5,
    title: "Emergency Loans",
    description:
      "Get quick relief during financial emergencies with our Sharia-compliant emergency loans. Fast approval, no collateral required, and competitive profit rates.",
    image: emergency,
    buttonName: "Explore Emergency Loans",
  },
  {
    id: 6,
    title: "Housing Loans",
    description:
      "Achieve your dream of homeownership with our Sharia-compliant housing loans. Transparent financing terms, fixed repayment schedules, and alternative structures to avoid interest (riba).",
    image: sharia_compliance,
    buttonName: "Explore Housing Loans",
  },
  {
    id: 7,
    title: "Qard Hasan Group Loans",
    description:
      "Join hands with others in your community with our Sharia-compliant Qard Hasan group loans. Interest-free loans provided on a goodwill basis, fostering cooperation and mutual support among members.",
    image: group_loans,
    buttonName: "Explore Group Loans",
  },
  {
    id: 8,
    title: "Salary-based Loans",
    description:
      "Meet your immediate financial needs with our Sharia-compliant salary-based loans. Structured on Islamic financial contracts, these loans offer transparent terms, fixed repayment schedules, and no interest-based transactions.",
    image: salaried_loans,
    buttonName: "Explore Salary-based Loans",
  },
];

export const ShowProgressBar = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <p>Loading...</p> <CircularProgress style={{ color: "#caa432" }} />
    </div>
  );
};

import React, { useState } from "react";
import GeneralFAQs from "./GeneralFAQs.component";
import IslamicComplianceFAQs from "./IslamicComplianceFAQs.component";
import ButtonComponent from "../buttons/Button.component";
import styled from "styled-components";

const FrequentlyAskedQuestionsPage = () => {
  const [activeTab, setActiveTab] = useState("generalFAQs");

  return (
    <Wrapper>
      <H3>Frequesntly Asked Questions</H3>
      <ButtonWrapper>
        <ButtonComponent
          name={"General FAQs"}
          width={"8rem"}
          className={
            activeTab === "generalFAQs" ? "active" : "changeColorsToDefault"
          }
          onClick={() => setActiveTab("generalFAQs")}
        />
        <ButtonComponent
          name={"Islamic Compliance FAQs"}
          width={"14rem"}
          className={
            activeTab === "islamicComplianceFAQs"
              ? "active"
              : "changeColorsToDefault "
          }
          onClick={() => setActiveTab("islamicComplianceFAQs")}
        />
      </ButtonWrapper>

      {activeTab === "generalFAQs" && <GeneralFAQs />}
      {activeTab === "islamicComplianceFAQs" && <IslamicComplianceFAQs />}
    </Wrapper>
  );
};

const H3 = styled.h3`
  margin: 16px 16px 0 16px;
`;

const ButtonWrapper = styled.div`
  margin: 10px 0;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
  border-radius: 0.5rem;
  gap: 10px;
  // width:100%;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .changeColorsToDefault {
    background-color: lightgray;
    color: var(--font-color);
  }
  .active {
    background-color: var(--bg-color);
    color: rgba(0, 0, 0, 0.7);
  }
`;

export default FrequentlyAskedQuestionsPage;

import React from "react";
import styled from "styled-components";

const AboutComponent = () => {
  return (
    <Div>
      <TitleWrapper>
        <H2>About Us</H2>
      </TitleWrapper>
      <Wrapper>
        <p>
          SpinMark Microbusiness SACCO Society Ltd, stands as the epitome of
          visionary leadership and financial empowerment since its establishment
          in 2023. More than a mere financial institution, we are the catalyst
          for positive change, driven by unwavering commitment to Islamic
          finance principles. Our comprehensive suite of member-driven services,
          encompassing Poverty Graduation Initiatives, Investment
          Diversification, Strategic Partnerships, and innovative Deposit &
          Savings Solutions, redefines financial empowerment. Focused on
          addressing the unique financial needs of microbusinesses, youth, and
          women, we propel economic growth and stability. Join us at SpinMark,
          where pioneering financial inclusion with integrity is not just a
          mission, but a reality.
        </p>
      </Wrapper>
    </Div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  p {
    font-size: 16px;
    font-weight: lighter;
    line-height: 1.5;
  }
`;

const Div = styled.div` 
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

const H2 = styled.h2`
  margin: 2px;
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 500px) {
    /* Mobile styles small */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 0;
  }
`;
export default AboutComponent;

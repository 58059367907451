import React from "react";
import styled from "styled-components"; 
import AboutComponent from "./About.component";
import OurServicesCards from "../our-services/OurServicesCards.component";

const AboutSection = () => {
  return (
    <Container id="about">
      <Div>
        <AboutComponent />
        <OurServicesCards />
      </Div>
    </Container>
  );
};

const Div = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 2fr); /* 2 columns */
  grid-gap: 10px; /* Gap between grid items */
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr; /* 1 column */
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px;
`;

export default AboutSection;

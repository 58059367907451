import React from "react";
import styled from "styled-components";
import { Underline } from "../../utils";
import { GiRotaryPhone } from "react-icons/gi";
import { MdEmail } from "react-icons/md";
import { TbWorld } from "react-icons/tb";

const ContactInfo = () => {
  const contacts = [
    {
      id: 1,
      icon: <GiRotaryPhone />,
      contactInfo: "+ 254-792564210",
    },
    {
      id: 2,
      icon: <MdEmail />,
      contactInfo: "info@spinmarksacco.com",
    },
    {
      id: 3,
      icon: <TbWorld />,
      contactInfo: "www.spinmarksacco.com",
    },
  ];
  return (
    <Div id="contact">
      <TitleWrapper>
        <H3>Contact Info</H3>
        <Underline width={"100px"} />
      </TitleWrapper>

      {contacts.map((contact) => {
        return (
          <Wrapper key={contact.id}>
            <div>
              <div key={contact.id} className="icons">
                {contact.icon}
              </div>
              <p>{contact.contactInfo}</p>
            </div>
          </Wrapper>
        );
      })}
    </Div>
  );
};

const Div = styled.div``;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  p {
    margin: 2px;
    padding: 3px 0;
  }

  div {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: row;

    gap: 8px;
  }

  .icons {
    color: var(--bg-color);
  }
`;

const H3 = styled.h3`
  margin: 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;
`;
export default ContactInfo;

import React from "react";
import styled from "styled-components";
import { Underline, ourServices } from "../../utils";
import { useNavigate } from "react-router";

const QuickLinks = () => {
  const navigate = useNavigate();
  const firstFourNews = ourServices.slice(0, 4); // Slice the first 4 items

  return (
    <Div>
      <TitleWrapper>
        <H3>Quick links</H3>
        <Underline width={"90px"} />
      </TitleWrapper>

      {firstFourNews.map((link, index) => {
        return (
          <Wrapper
            key={link.id}
            onClick={() =>
              navigate("/quick-links-details", { state: { index } })
            }
          >
            <p>{link.title}</p>
            <Underscore />
          </Wrapper>
        );
      })}
      <Wrapper onClick={() => navigate("/FAQs")}>
        <p>FAQs</p>
        <Underscore />
      </Wrapper>
    </Div>
  );
};

const Div = styled.div``;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  p {
    margin: 2px;
    padding: 3px 0;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;
`;
const Underscore = styled.div`
  height: 0.08px;
  margin: 2px;
  background-color: var(--bg-color);
`;

const H3 = styled.h3`
  margin: 0;
`;
export default QuickLinks;
